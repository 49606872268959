import React,{useState} from "react"
import TextField from '@mui/material/TextField';
import { useSiteMetadata } from "../hooks/use-site-metadata"
import algoliasearch from 'algoliasearch';
import {navigate} from 'gatsby'
import axios from 'axios'
import _ from "lodash";

let searching = false


function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

function makeHilightMap(hits) {
    const hightlightMap = {}
    hits.forEach( (hit) => {
        const hilights = hit._highlightResult
        const productKey = hit.productKey
        hightlightMap[productKey] = hilights
    })

    return hightlightMap
  }

function gtag(...args: any[])
{
const win:any=window;
if ('gtag' in win) {
    win.gtag(...args)
}
else {
    win.dataLayer = win.dataLayer || [];
    win.dataLayer.push(args);
}
}

export function report_event(type:string,properties:any) {
    gtag('event', type, properties);
}

export default function Search(props) {
    let {id, filterGroupings,onChange, state, order,filters,pageNum,placeholder, setProdObjMapping, setHighlightStrMap, legacy, homepage} = props
    const { application_api_key, application_id, product_url,indexName } = useSiteMetadata()
    let productSearched=false

    let {extra_spdc_option, extra_validators} = state

    let filtersAlgoliaForExtraFields:any=[]
    let filter_events:any = []

    if (!!extra_spdc_option) {
        filtersAlgoliaForExtraFields.push(["spdc_option:true"])
        filter_events.push({
            name:'filter',
            details:{
                field: "spdc_option",
                value: "SDPC Agreement",
            }})
    }
    if (!!extra_validators && extra_validators.length>0)
    {
        let validatorFilters:any = []
        filtersAlgoliaForExtraFields.push(validatorFilters)
        for (let validator of extra_validators) {
            validatorFilters.push(`validatorName:${validator}`)
            filter_events.push({
                name:'filter',
                details:{
                    field: "validation",
                    value: validator,
                }})
        }        
    }

    // let Search.sID:NodeJS.Timeout = null
    const [searchTimeoutID, setSearchTimeoutID] = useState<any>()
    // searchTimeoutID.current.value=0

    let numericFilters:any = []
    // @TODO: Make all supportedLanguage And logic
    if (!!filters)
    {
        if (!!filterGroupings)
        {
            let groupLookup:any = {}
            for (let filter of filters)
            {
                filter_events.push({
                    name:'filter',
                    details:{
                        field: filter.fieldName,
                        value: filter.value,
                    }})
                if (!groupLookup[filter.fieldName])
                {
                    groupLookup[filter.fieldName] = []
                }
                numericFilters = groupLookup[filter.fieldName]
                numericFilters.push("mv_items:"+filter.key)
            }
            numericFilters = []
            for (let groupName of Object.keys(groupLookup))
            {
                numericFilters.push(groupLookup[groupName])
            }
        }
        else
        {
            for (let filter of filters)
            {
                numericFilters.push("mv_items:"+filter.key)
            }
            numericFilters = [numericFilters]
        }
    }

    let highlightStrMap ={}

    
    let hitTranslator = (hits,page_info) =>
    {
        let recordIds:any = []
        let prodObjMapping = {}
        let rankingLookup = {}

        // hits contains algolia <em> brackets
        for (let hit of hits)
        {
            recordIds.push(hit.recordId)
            prodObjMapping[hit.recordId] = hit.objectID
            if (hit.featuredRank) rankingLookup[hit.recordId] = hit.featuredRank
        }
        setProdObjMapping(prodObjMapping, page_info.queryID)

        let hilightmap = makeHilightMap(hits)
        setHighlightStrMap(hilightmap)

        // filters : include fieldName, value, key, uniqueId
        
        if (recordIds.length == 0) {
            console.log("No records")
            onChange([], page_info)
            return
        }

        axios.post(product_url, {recordIds})
        .then(function (response) {
            // TODO: Error handling
            if (response?.data?.result=="ok")
            {
                productSearched=true
                let prodLookup = {}

                for (let prod of response.data.prods)
                {
                    prodLookup[prod.productKey] = prod
                }
                let orderedProds:any = []
                for (let key of recordIds)
                {
                    const prod = prodLookup[key]
                    if (rankingLookup[key]) prod.featuredRank = rankingLookup[key]

                    report_event('prod_impression',{product_key:prod.productKey})
                    orderedProds.push(prod)
                }

                for (let event of filter_events) {
                    report_event(event.name,event.details)
                }
                
                onChange(orderedProds,page_info)
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
          })

        if (!productSearched) {            
            let orderedProds = []
            onChange(orderedProds,page_info)
        }
    }

    const client = algoliasearch(application_id, application_api_key)
    if (pageNum<0)
    {
        pageNum = 0
    }

    // let facetFilters= numericFilters
    let facetFilters = [...numericFilters, ...filtersAlgoliaForExtraFields]
    let search = (term) =>
    {
        index.search(term,{
            "page": pageNum,
            "getRankingInfo": false,
            "clickAnalytics": true,
            "hitsPerPage": 24,
            "facets": [
                "mv_items",
                "spdc_option",
                "validatorName",
            ],
            "facetFilters": facetFilters
        }).then(({hits,hitsPerPage,nbHits,nbPages,page,queryID}) => {
            let page_info = {hitsPerPage,nbHits,nbPages,page,queryID}
            hitTranslator(hits,page_info)
            report_event('searchTerm',{value:term})
        })
    }

    // about selecting with index of algolia to use.
    let index = (order=='name')?client.initIndex(indexName+"_name"):client.initIndex(indexName)

    // by relevance
    index = (order=='relevance')?client.initIndex(indexName+"_relevance"):index

    let searchChange = (event) => {
        state.searchTerm = event.target.value
        window.localStorage['searchTerm'] = state.searchTerm
        pageNum = state.pageNum = 0

        // Check for backspace to empty searchTerm but not back to all products
        // to delay
        clearTimeout(searchTimeoutID)
        setSearchTimeoutID(_ => setTimeout(_ => {search(state.searchTerm)}, 500))
    }

    // state.searchTerm stores the manual search text
    if (!state.initSearch)
    {
        state.initSearch = true
        search(state.searchTerm)
    }


    if (legacy) return <TextField 
    defaultValue={state.searchTerm}
    key="searchField"
    autoFocus={searching==true}
    onBlur={()=>{searching=false}}
    onFocus={()=>{searching=true}}
    id={id} placeholder={placeholder} variant="outlined"
    onChange={searchChange}
    ></TextField>

    return <div className="searchContainer"><input 
        defaultValue={state.searchTerm}
        key="searchField"
        autoFocus={searching==true}
        onBlur={()=>{searching=false}}
        onFocus={()=>{searching=true}}
        id={id} placeholder={placeholder}
        onChange={searchChange}
    >
    </input>
    {!homepage && <div className="searchBtn" onClick={()=>{navigate('/')}}>Search</div>}
    </div>

}
