import React, { createRef } from 'react';
import '../app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  createTheme,
  ThemeOptions,
  ThemeProvider
} from '@mui/material/styles';
import useScript from '../hooks/useScript';
import { Chip,FormControlLabel,Checkbox} from '@mui/material'
import { graphql,navigate,Link,useStaticQuery } from 'gatsby'
import { Helmet } from "react-helmet"
import Button from 'react-bootstrap/Button'
import DeviceDetector from "device-detector-js"
const deviceDetector = new DeviceDetector
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { MVLookupsForAFieldName} from "../util/ChildFiltersHelper"; 
import UserLogin from './userLogin'
import Searcher from "./search"
import ExplorePopup from "./explorePopup"
const aa = require("search-insights")
const { v4: uuidv4 } = require('uuid');

import * as Sentry from "@sentry/react";

import amplitude from 'amplitude-js';

const AMP_API_KEY = 'f41e8e6d5b166835f4ae8ff7ccd072ac'

import axios from 'axios'
import LoginRequiredPopup from "./loginRequiredPopup";
export interface LandingProps {
  productFieldOrderMeta: any,
  validator_names: any,
  classes: any,
}

const filterInfo = [
  {label:'Audience',iconClass:'icon_audience',all:false,choice:0},
  {label:'Level',iconClass:'icon_level',all:false,choice:3},
  {label:'Validations',iconClass:'icon_validations',all:false,choice:99},
  {label:'Category',iconClass:'icon_category',all:false,choice:1},
  {label:'Discipline',iconClass:'icon_discipline',all:false,choice:2},
  {label:'Price',iconClass:'icon_price',all:false,choice:4},
  {label:'All Filters',iconClass:'all_filters',all:true,choice:0},
];

const filterColumnCount = {
  educationalLevel: 2,
  learningTechnologyCategory: 2,
  academicSubjectArea: 3,
  teachingAndLearningApproaches: 2,
  compatibleLearningManagementSystems: 2,
  interoperabilityAuthenticationSSO: 2,
}

const excluded_fields = {
  subcategory: true,
  topic: true,
  contentAttributes: true,
  learnerProgressTracking: true,
  instructionalFeedback: true,
  communicationFeatures: true,
  inappPurchases: true,
  productTraining: true,
}

const ignoreFilters = {
  'learnerData': true,
  'accessEducationalRecord': true,
  'accessDemographicData': true,
  'interoperabilityCost': true,
  'interoperabilityAuthorization': true,
  'accessAggregate' : true,
  'supportedLanguage': true,
}

export const headingIcons = {
  'learningAudience': <i className="fal fa-users"></i>,
  'educationalUse': <i className="fal fa-layer-group"></i>,
  'academicDiscipline': <i className="fal fa-file-certificate"></i>,
  'learningInteraction' : <i className="fal fa-stars"></i>,
  'pricingDescription': <div className="pricingIcon"></div>,
  'productType': <i className="fal fa-sliders-v"></i>,
  'product_validation_heading': <i className="fal fa-shield-check"></i>,
  'compatibleLearningManagementSystems': <i className="fal fa-random"></i>,
  'spdcNationalDataPrivacyAgreementLabel': <i className="fal fa-user-lock"></i>
}

export const hideHeadings = {
  'compatibleLearningManagementSystems': 'Interoperability',
  'spdcNationalDataPrivacyAgreementLabel':'Privacy',
}

// headingLookup for filters
export const headingLookup = {
  'learningAudience': "Audience and Users",
  'educationalUse': 'Product Categories',
  'academicDiscipline':'Subjects and Standards',
  'learningInteraction':'Features',
  'productType': 'Tech Specifications',
  'compatibleLearningManagementSystems': 'Interoperability',
  'spdcNationalDataPrivacyAgreementLabel':'Privacy',
  'pricingDescription':'Price',
}

export let specialMapping = {
  'Kindergarten': 'K',
  '1st Grade': '1',
  '2nd Grade': '2',
  '3rd Grade': '3',
  '4th Grade': '4',
  '5th Grade': '5',
  '6th Grade': '6',
  '7th Grade': '7',
  '8th Grade': '8',
  '9th Grade': '9',
  '10th Grade': '10',
  '11th Grade': '11',
  '12th Grade': '12',
}

export let fieldOverrides = {
  spdcNationalDataPrivacyAgreementLabel: 'SDPC National Data Privacy Agreement Label',
  learningTechnologyCategory: 'Product Category',
  academicSubjectArea : 'Content Area',
  academicContentStandards : 'Content Standards',
}

const CACHE_TIMEOUT_DEFUALT = 3000

let themeOptions:ThemeOptions = {
    palette: {
        primary: {
            main: "#017961",
            contrastText: '#ffffff'
        },
        secondary: {
            main: "#001631",
            contrastText: '#ffffff'
        },
        text: {
            primary: "#000000",
        },
        info: {
            main: "#0080a4",
        }
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
}
let theme = createTheme(themeOptions)

let save_group_names = ['headers','filter','my_list','compare','extras','results']
const save_group_defaults = {
  headers: '{"validations_heading":true}',
}

function gtag(...args: any[])
{
  const win:any=window;
  if ('gtag' in win) {
    win.gtag(...args)
  }
  else {
    win.dataLayer.push(args);
  }
}

export function report_event(type:string,properties:any) {
  amplitude.getInstance().logEvent(type,properties);
  gtag('event', type, properties);
}

class chainCommands {
  command1: any
  command2: any

  constructor() {
  }

  runCommands() {
    this.command1()
    this.command2()
  }
}

export class stateFlipper {
  fieldName:string
  groupName:string
  stateNames:any
  controller:any

  constructor(fieldName:string,groupName:string,stateNames:any,controller:any) {
    this.fieldName = fieldName
    this.groupName = groupName
    this.stateNames = stateNames
    this.controller = controller
  }

  setAllTrue()
  {
    let jsonValues = window.localStorage['filter']
    if (!jsonValues) jsonValues = {}
    else jsonValues = JSON.parse(jsonValues)

    let currentFilters = this.controller.state.filters
    let newStates:any = {initSearch: false,filters:currentFilters}
    for (let filter of this.stateNames)
    {
      currentFilters.push(filter)
      newStates[filter.uniqueId] = true
      newStates['selection_'+filter.uniqueId] = true
      jsonValues['selection_'+filter.uniqueId] = true
    }

    window.localStorage['filter'] = JSON.stringify(jsonValues)

    this.controller.setState(newStates)
  }

  setAllFalse() {
    let jsonValues = window.localStorage['filter']
    if (!jsonValues) jsonValues = {}
    else jsonValues = JSON.parse(jsonValues)

    let currentFilters = this.controller.state.filters
    let newFilters:any = []
    let newStates:any = {initSearch: false,filters:newFilters}
    for (let filter of currentFilters)
    {
      if (filter.fieldName!=this.fieldName)
      {
        newFilters.push(filter)
      }
      else
      {
        newStates[filter.uniqueId] = false
        newStates['selection_'+filter.uniqueId] = false
        delete jsonValues['selection_'+filter.uniqueId]
      }
    }

    window.localStorage['filter'] = JSON.stringify(jsonValues)

    this.controller.setState(newStates)
  }

}

export class toggleCtrl {
  unique_id: any
  controller: any
  save_group: any
  onUpdate: any
  state: any
  value: any
  groupName:string
  eventTrack: any

  constructor(props) {
    let {groupName,state,value,controller,unique_id,save_group,onUpdate,eventTrack} = props
    this.state = state
    this.value = value
    this.controller = controller
    this.unique_id = unique_id
    this.save_group = save_group
    this.onUpdate = onUpdate
    this.groupName = groupName
    this.eventTrack = eventTrack
  }

  toggleSelection()
  {
    this.state = !this.state
    this.changeSelection()
  }

  toggleValue() {
    // if no current state, add the value to a new array
    if (this.state == null)
    {
      this.state = [this.value]
    }
    else
    {
      // if there is am array already...
      let found = false;
      // this will be the new values
      let newState:any = []
      for (let val of this.state) {
        // If we find it, then don't add it to the newState (toggled)
        if (val == this.value) {
          found = true;
        }
        else {
          newState.push(val)
        }
      }

      // If it's not found, then add it to the newState
      if (!found) {
        newState.push(this.value);
      }

      // Update state
      this.state = newState
    }

    this.changeSelection()
  }

  changeSelection()
  {
    let {groupName,state,controller,unique_id,save_group,onUpdate,eventTrack } = this
    let newState = {pageNum:0}
    newState[unique_id] = state

    if (!!save_group)
    {
      let jsonValues = window.localStorage[save_group]
      if (!jsonValues) jsonValues = {}
      else jsonValues = JSON.parse(jsonValues)
      if (!!state && !!state[groupName])
      {
        newState[groupName] = state[groupName]
      }

      if (newState[unique_id])
      {
        jsonValues[unique_id] = newState[unique_id]
        if (!!groupName)
        {
            if (!newState[groupName])
            {
                newState[groupName] = {}
            }
            newState[groupName][unique_id] = newState[unique_id]
        }
      }
      else
      {
        delete jsonValues[unique_id]
        if (!!groupName)
        {
            if (!!newState[groupName])
            {
                delete newState[groupName][unique_id]
            }
            
        }
      }
      window.localStorage[save_group] = JSON.stringify(jsonValues)
      if (!!onUpdate)
      {
        onUpdate()
      }
    }
    controller.setState(newState)

    if (eventTrack) {
      report_event(eventTrack.type,eventTrack.properties)
    }
  }
  
}


class CommonProduct extends React.Component<LandingProps> {
  state: any = {
    files: [],
    showUpload: false,
    showMsg: false,
    reload:true,
    productFieldOrderMeta:{},
    validator_names: [],
    mv_lookups:{},
    filtered_record_key_lookup: {},
    products: [],
    filters: [],
    focus:0,
    order:'relevance',
    my_product_keys: [],
    myListOpen: false,
    navOpen: false,
    firstTime: false,
    lastComparedProducts:[],
    lastMyProductKeys:[],
    validations_heading: true,
    showLogin: false,
    userData: false,
    dynamicPopup: null,
  }
  isBrowser: boolean
  myStorage:any = {}
  filterTree:any = {}
  isTablet: boolean = false
  firstRender: boolean = true
  elementName:string
  login_url: string
  logout_url: string
  vendor_form_target: string
  user_detail_url: string
  save_user_detail_url: string

  contentRef: any
  homepage: boolean = false
  loadLock: boolean = false

  constructor(props)
  {
    super(props)

    this.isBrowser = typeof window !== "undefined"

    if (this.isBrowser)
    {
        try
        {
          this.syncFilters(true)
        }
        catch (e)
        {
          console.log(e)
        }

        this.myStorage = window.localStorage

        let logoutRedirect = this.myStorage.getItem('logoutRedirect');
        if (logoutRedirect) {
          localStorage.removeItem('logoutRedirect');
          window.location = logoutRedirect;
        }

        for (let groupName of save_group_names)
        {
          if (!(groupName in window.localStorage) && groupName in save_group_defaults)
          {
            window.localStorage[groupName] = save_group_defaults[groupName]
          }
          if (!!window.localStorage[groupName])
          {
            let jsonValues = window.localStorage[groupName]
            if (!!jsonValues)
            {
              jsonValues = JSON.parse(jsonValues)
              for (let key of Object.keys(jsonValues))
              {
                this.state[key] = jsonValues[key]
              }
            }
          }
        }

        this.state.searchTerm = ''
        if ('searchTerm' in window.localStorage)
        {
          this.state.searchTerm = window.localStorage['searchTerm']
        }

        if ('exploreChoice' in window.localStorage)
        {
          this.state.showExploration = true
          this.state.exploreChoice = window.localStorage['exploreChoice']
          window.localStorage.removeItem('exploreChoice')
        }

        if (!!location.search)
        {
          let terms = location.search.split('?search=',2)
          if (terms.length==2)
          {
            this.state.searchTerm = decodeURI(terms[1])
            window.localStorage['searchTerm'] = this.state.searchTerm
            history.replaceState({}, '', location.pathname);
          }
          terms = location.search.split('?focus=',2)
          if (terms.length==2)
          {
            this.state.showExploration = true
            this.state.exploreChoice = parseInt(terms[1])
          }
        }
  
        if (!this.myStorage.visited)
        {
          this.state.firstTime = true
          this.myStorage.visited = true
        }

        this.loadLock = false

        let productState = {}

        if (!!this.myStorage.my_list)
        {
            productState = JSON.parse(this.myStorage.my_list)
        }
        
        for (let key of Object.keys(productState))
        {
            let productKey = key.split('_')[1]
            this.state.my_product_keys.push(productKey)
        }
        this.contentRef = createRef();
    }
    try
    {
      if (!!this.myStorage.productFieldOrderMeta)
      {
        this.state.productFieldOrderMeta = JSON.parse(this.myStorage.productFieldOrderMeta)
        this.processMetaData(this.state.productFieldOrderMeta)
      }
      if (!!this.myStorage.mv_lookups)
      {
        this.state.mv_lookups = JSON.parse(this.myStorage.mv_lookups)
      }
      if (!!this.myStorage.validator_names)
      {
        this.state.validator_names = JSON.parse(this.myStorage.validator_names)
        if (this.state.validator_names.length>0 && !this.isObjectWithNameProperty(this.state.validator_names[0]) )
        {
          this.myStorage.removeItem('metaDate')  // Validator has changed structure, if not of structure, reload metadata.
        }
      }

    }
    catch (e)
    {
      console.log(e)
    }
  }

  isObjectWithNameProperty(variable) {
    return (
      variable !== null && // Ensure the variable is not null
      typeof variable === 'object' && // Check if the variable is an object
      !Array.isArray(variable) && // Ensure the object is not an array
      'name' in variable // Check if the object has a 'name' property
    );
  }

  componentDidMount() {
    this.restoreScrollPosition();
  }

  componentWillUnmount() {
    this.saveScrollPosition();
  }

  componentDidUpdate(prevProps, prevState) {
    let doc:any = document
    this.updateCheckboxStates()
    if (doc.renderWidget) {
      doc.renderWidget()
    }
    if (this.contentRef.current) {
      this.restoreScrollPosition();
    }
    else {
      localStorage.setItem('scrollPosition', '0');
    }
  }
  
  getKeysFromLookupMeta(meta)
  {
    var value_keys:any = []
    if (!!meta)
      for (let value of Object.keys(meta) )
      {
        let intVal = Number.parseInt(value)
        if (!!intVal && Number.isInteger(intVal) && !!meta[intVal])
        {
          value_keys.push(intVal)
        }
      }
    return value_keys
  }

  createLookupFromArray(list)
  {
    let lookup = {}
    if (!list)
    {
      return lookup
    }
    for (let item of list)
    {
      lookup[item] = true
    }
    return lookup
  }

  processMetaData(productFieldOrderMeta)
  {
    let newTree:any = {}
    let currentHeading:any = null

    if (!!productFieldOrderMeta)
    {
      let pmo = productFieldOrderMeta
      if (!!pmo?.keys)
      {
        for (let key of pmo.keys)
        {
          let meta_list = pmo[key]
          for (let meta of meta_list){
            if (meta.tableName=='products')
            {
              if (headingLookup[meta.columnName])
              {
                currentHeading=meta.columnName
                newTree[currentHeading] = {}
              }
              if (!!currentHeading)
              {
                newTree[currentHeading][meta.columnName] = meta
              }
            }}
        }
      }
    }
    this.filterTree = newTree
  }

  metaLoaded(data)
  {
    this.processMetaData(data.productFieldOrderMeta)
    this.setState({productFieldOrderMeta:data.productFieldOrderMeta,mv_lookups:data.mv_lookups,validator_names:data.validator_names})
  }

  saveMyList(json) {
    axios.post(this.save_user_detail_url, json, {
      headers: {
        'Content-Type': 'application/json',
      }})
    .then(function (response) {
        // TODO: Error handling
        console.log(response);
    })
    .catch(function (error) {
        // handle error
        console.log(error);
      })    
  }

  updateMyList(){
    let productState = {}
    this.state.my_product_keys = []

    if (!!this.myStorage.my_list)
    {
        productState = JSON.parse(this.myStorage.my_list)
        this.saveMyList(productState)
    }

    let compareLookup = {}
    for (let key of this.state.compare_keys)
    {
      compareLookup[key] = true
    }
    
    
    for (let key of Object.keys(productState))
    {
        let productKey = key.split('_')[1]
        delete compareLookup[productKey]
        this.state.my_product_keys.push(productKey)
    }

    let remnants = Object.keys(compareLookup)
    if (remnants.length>0)
    {
      for (let key of remnants)
      {
        delete this.state.compare_lookup['compare_'+key]
        delete this.state['compare_'+key]
      }
      window.localStorage['compare'] = JSON.stringify(this.state.compare_lookup)
    }

  }

  updateCompareList(new_state)
  {
    let count = this.state.compare_keys?.length || 0
    if (new_state)
    {
      count++
    }
    else
    {
      count--
    }
    if (count>=10)
    {
      this.state.compare_maxed = true
    }
    else
    {
      this.state.compare_maxed = false
    }
  }

  getFromLocalstorage(listName)
  {
    let isBrowser = typeof window !== "undefined"
    let my_product_keys:any = []
    let productState = {}
  
    if (isBrowser)
    {
      let myStorage= window.localStorage
      if (!!myStorage[listName])
      {
        productState = JSON.parse(myStorage[listName])
        
        for (let key of Object.keys(productState))
        {
            let productKey = key.split('_')[1]
            my_product_keys.push(productKey)
        }
      }
    }
    return {my_product_keys, my_product_lookup:productState}
  }

  Collapsable(heading, fieldName, open, icon:any=null)
  {

    let openState = this.state[fieldName] ///   can be : undefined, true
    
    let bound_values:any = {unique_id:fieldName,state:openState,controller:this,save_group:'headers'}
    

    let toggler:toggleCtrl = new toggleCtrl(bound_values)

    let clickFunction = toggler.toggleSelection.bind(toggler)

    // heading of each sidebar section ??
    return <div className="heading" id={fieldName} onClick={clickFunction}>
      {
        icon
      }
      <span>{heading}</span>
      <a className={open?"up_arrow":"down_arrow"} onClick={null}> </a>
    </div>
  }

  selectAllToCompare()
  {
    if (!!this.state.my_product_keys)
    {
      let state = this.state
      this.state.my_product_keys.map((product_id,indx)=>{
            let product_key = 'compare_'+product_id
            state.compare_lookup[product_key] = true
            state[product_key] = true
        })


      window.localStorage['compare'] = JSON.stringify(state.compare_lookup)
      this.setState({})
    }

  }

  deselectAllToCompare()
  {
    if (!!this.state.my_product_keys)
    {
      let state = this.state
      state.compare_lookup = {}
      this.state.my_product_keys.map((product_id,indx)=>{
            let product_key = 'compare_'+product_id
            delete state[product_key]
        })

      window.localStorage['compare'] = JSON.stringify(state.compare_lookup)
      this.setState({})
    }

  }

  showProperty(fieldName, mv_lookup,exceptions=null)
  {
    const lookup = this.state.mv_lookups.products[fieldName]
    let keys = this.getKeysFromLookupMeta(lookup)
    let empty = true

    let rendered = keys.map((key,index)=>{
        if (mv_lookup[key] && (!exceptions || !exceptions[lookup[key]]) )
        {
          empty = false
          return <span key={fieldName+"_"+key} className="property mv_item">{lookup[key]}</span>
        }
      })

    if (empty)
    {
      return null
    }
    return rendered

  }

  showPropertyPill(fieldName, mv_lookup, prefix, exceptions=null)
  {
    const lookup = this.state.mv_lookups.products[fieldName]
    let keys = this.getKeysFromLookupMeta(lookup)
  
    let empty = true

    let rendered = keys.map((key,index)=>{
        if (mv_lookup[key] && (!exceptions || !exceptions[lookup[key]]) )
        {
          empty = false
          return <Chip key={prefix+fieldName+"_"+key} label={lookup[key]}/>
        }
      })

    if (empty)
    {
      return null
    }
    return rendered
  
  }

  handleScroll(event) {
    let height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) + 1
    let scrollable_offset_max = event.srcElement.body.scrollHeight - height
    let scrollRatio = window.scrollY / scrollable_offset_max

    let element = document.getElementById(this.elementName)
    if (!!element)
    {
      let style = getComputedStyle(element)
      let validation_box_height = 77 + (parseInt(style.height) || 0 ) + 10
      let offscreen = validation_box_height - height

      let maxedScroll = window.scrollY
      if (maxedScroll>(validation_box_height-200))
      {
        maxedScroll = (validation_box_height-200)
      }
      let newMarginTop = 77 - maxedScroll // (offscreen*scrollRatio)
      element.style.marginTop = newMarginTop+'px'
    }
      
  }

  getSessionId() {
    // Check if a sessionId is already stored in local storage
    let sessionId = localStorage.getItem('sessionId');
  
    // If a sessionId is not found in local storage, generate a new one
    if (!sessionId) {
      sessionId = 'anon-'+uuidv4();
      localStorage.setItem('sessionId', sessionId);
    }
  
    return sessionId;
  }

  productTrack(prod, position) {
    aa('clickedObjectIDsAfterSearch', {
      userToken: this.getSessionId(),
      index: this.state.indexName,
      queryID: this.state.queryID,
      eventName: 'View Product',
      positions: [position+1],
      objectIDs: [this.state.prodObjMapping[prod.productKey]]
    })
  }

  setLoggedIn( state )
  {
    let savedData = state.savedData || {}
    let oldData:any = this.myStorage.my_list?JSON.parse(this.myStorage.my_list):{};
    this.myStorage.my_list = JSON.stringify(savedData)
    let my_product_keys:any = []
    let newState = {userData:state,my_product_keys}
    for (let key of Object.keys(oldData))
    {
      newState[key] = null
    }

    for (let key of Object.keys(savedData))
      {
          let productKey:any = key.split('_')[1];
          my_product_keys.push(productKey);
          newState[key] = savedData[key]
      }    
    this.setState(newState)
  }

  search(products, page_info)
  {
    if (page_info.page>0) {
      products = this.state.products.concat(products)
    }
    this.setState({products,page_info})  // products setState here
  }

  setProdObjMapping(prodObjMapping, queryID)
  {
    this.state.prodObjMapping = prodObjMapping
    this.state.queryID = queryID
  }

  setHighlightStrMap(hlMap){  //  highlighting string map from search.tsx
    this.setState({highlightStrMap:hlMap})
  }

  clearAllFilters()
  {
    let newState:any = {initSearch:false,filters:[],extra_validators:[], extra_spdc_option:false}
    let filterJSONValues = JSON.parse( (this.isBrowser && window.localStorage['filter']) || "{}" )
    for (let complexKey of Object.keys(filterJSONValues))
    {
      if (filterJSONValues[complexKey])
      {
        newState[complexKey] = false
      }
    }
    window.localStorage['filter'] = "{}"
    window.localStorage['extras'] = "{}"
    this.setState(newState)
  }

  private prepareSelectionJsx(fieldName: any, value: any, renderCount: number, openCount: number, childFilters: any[], our_lookups: any) {
    let stateName = 'selection_' + fieldName + '_' + value
    let state = this.state[stateName] == true
    renderCount++
    if (state)
      openCount++

    else {
      childFilters.push({
        fieldName,
        key: value,
        uniqueId: fieldName + '_' + value,
        value: our_lookups[value],
      })
    }
    return { renderCount, openCount }
  }

  displayField(meta:any, headingName:string, ref:any)
  {
    let {mv_lookups} = this.state

    let fieldName=meta.columnName

    if (excluded_fields[fieldName])
      return null;

    let openState = true

    if (!!mv_lookups.products[fieldName])
    {
      let renderedItems:any = <></>
      if ( ignoreFilters[fieldName] ) {
        return null
      }

      let allSelectedName = fieldName+'_all'

      var our_lookups = (new MVLookupsForAFieldName(mv_lookups.products[fieldName])).getOurLookups()

      // our_lookups --> meta
      // our_lookup needs to get filtered
      var value_keys = this.getKeysFromLookupMeta(our_lookups)
      let renderCount = 0
      let openCount = 0
      let childFilters = []
      let renderedColumns:any = []
      let columnCount =  filterColumnCount[fieldName] || 1
      let columnContains = filterColumnCount[fieldName]? Math.ceil(value_keys.length/filterColumnCount[fieldName]): value_keys.length
      let columnOn = 0

      value_keys.map((value, index)=>{
        ({ renderCount, openCount } = this.prepareSelectionJsx(fieldName, value, renderCount, openCount, childFilters, our_lookups))

        if ( (index-(columnOn*columnContains)) == columnContains )
        {
          columnOn++
          renderedColumns.push(renderedItems)
          renderedItems = <></>
        }
        renderedItems = <>{renderedItems}{this.Selection(our_lookups[value],fieldName+'_'+value, headingName)}</>
      })


      // found this.state contains only childFilters that had been opened in sidebar by end user

      // if (excludeFlag) return null

      let changer = new stateFlipper(fieldName,headingName,childFilters,this)

      let updateFn = null

      if (openCount==renderCount)
      {
        updateFn = changer.setAllFalse.bind(changer)
        this.state['selection_'+allSelectedName]=true
      }
      else
      {
        updateFn = changer.setAllTrue.bind(changer)
        this.state['selection_'+allSelectedName]=false
      }

      // allSelector is added above renderedItems ; that combines the sidebar filter menu
      let allSelector:any = <div className="allSelector">{this.Selection('Select All',allSelectedName, 'allSelected', 'allSelected',updateFn)}</div>

      let fieldLabel = fieldOverrides[fieldName] || meta.displayName
      
      return <div key={'topic_'+fieldName} id={'topic_'+fieldName} className={"indented "+(openState?"open":"closed")} ref={ref}>
        <div className="filterCol">
          {this.Collapsable(fieldLabel, fieldName, openState)}
          {allSelector}
        </div>
        {renderedColumns.map( (rendered,index) => {
          return <div key={`${fieldLabel}_col_${index}`} className={`filterCol of${columnCount}`}>{rendered}</div>;
        } )}
        <div className={`filterCol of${columnCount}`}>{renderedItems}</div>
        <div className='dividingLine clear'/>
        </div>
    }
    return null
  }

  Selection(value, unique_id, groupName,save_group='filter',onUpdate=null)
  {

    // this.state (of class commonProduct)
    let state = this.state['selection_'+unique_id]==true
    let bound_values:any = {
      groupName,
      state,
      controller:this,
      save_group,
      unique_id:'selection_'+unique_id,
      onUpdate: onUpdate || this.syncFilters.bind(this)
    }

        
    let toggler:toggleCtrl = new toggleCtrl(bound_values)

    let clickFunction = toggler.toggleSelection.bind(toggler)

    const chainedCommands = {
      command1: () => {
        this.saveScrollPosition()
      },
      command2: clickFunction,
      state: this.state
    }

    let commands:chainCommands = new chainCommands()
    clickFunction = commands.runCommands.bind(chainedCommands)    


    // NOTE: className selection uses FormControlLabel

    return <div key={"selection_"+unique_id} className="selection">
      <FormControlLabel
        control={<Checkbox size="small" disableRipple={true} checked={state} onClick={clickFunction} color="primary" />}
        label={value}
        labelPlacement="end"
      />
    </div>
  }

  // Determines if the filters between the state values and the localstorage ones are in sync, then calls the search to sync if needed.

  syncFilters(initializing=false)
  {
    let {mv_lookups} = this.state

    let filters:any = []
    // build a list in filters on values in the localstorage
    let filterJSONValues = JSON.parse( (this.isBrowser && window.localStorage['filter']) || "{}" )
    for (let complexKey of Object.keys(filterJSONValues))
    {
      let [sel, fieldName, keyStr] = complexKey.split('_')  // may split extra_badges*
      

      if( (filterJSONValues[complexKey])
          && (fieldName!='extra')            //suppressed those with "extra" fieldName
          )
      {

        filters.push( {
          fieldName,
          value: mv_lookups.products[fieldName][keyStr],
          key: keyStr,
          uniqueId: fieldName+'_'+keyStr
        } )

      }
    }

    let filter_sync = false;

    // See if the filters count is the smae at least (when only adding or only subtracting at a time, this is sufficient)
    if (!!this.state.filters && this.state.filters.length == filters.length)
    {
        filter_sync = true;
        for (let fIndx=0;fIndx<filters.length;fIndx++)
        {
            if (this.state.filters[fIndx].key!=filters[fIndx].key)
            {
                filter_sync = false;
                break;
            }
        }

    }

    let extras = JSON.parse( (this.isBrowser && window.localStorage['extras']) || "{}" )
    let extra_validators = extras['extra_validators'] || []
    let extra_spdc_option = extras['extra_spdc_option'] || false

    if (this.state.extra_spdc_option != extra_spdc_option )
    {
      filter_sync = false;
    }

    if (!this.state.extra_validators || this.state.extra_validators.length != extra_validators.length) {
      filter_sync = false;
    }

    if (!!this.state.extra_validators)
    {
      let validator_lookup = {}
      for (let validator of this.state.extra_validators)
      {
        validator_lookup[validator] = true
      }

      for (let validator of extra_validators)
      {
        if ( !( validator in validator_lookup) ) {
          filter_sync = false;
        }
      }
    }

    if (!filter_sync)
    {

      if (initializing)
      {
        this.state.filters = filters
        this.state.initSearch = false
        this.state.extra_validators = extra_validators
        this.state.extra_spdc_option = extra_spdc_option
      }
      else
      {
        this.setState({
            filters,
            extra_validators,
            extra_spdc_option,
            initSearch: false
        })
      }
    }
  
  }

  updateResults()
  {
    this.setState({
      initSearch: false
    })
  }


  FilterChip(value, unique_id)
  {
    let state = this.state['selection_'+unique_id]==true
    let bound_values:any = {unique_id:'selection_'+unique_id,state,controller:this,save_group:'filter',onUpdate:this.syncFilters.bind(this)}

    let toggler:toggleCtrl = new toggleCtrl(bound_values)
    let clickFunction = toggler.toggleSelection.bind(toggler)

    return <div key={unique_id}>
    <Chip label={value}/><i onClick={clickFunction} className="closeChip fal fa-times"></i>
    </div>
  }

  BadgeChip(value){
    const uniqueIdPrefixed = 'extra_validators'
    const unique_id = uniqueIdPrefixed + '_'+value

    let state = this.state[uniqueIdPrefixed] || []

    let bound_values:any = {
      groupName: 'validators',
      state,
      value,
      controller:this,
      save_group:'extras',
      unique_id: uniqueIdPrefixed,
      onUpdate: this.syncFilters.bind(this)
    }

        
    let toggler:toggleCtrl = new toggleCtrl(bound_values)

    let clickFunction = toggler.toggleValue.bind(toggler)

    return  <div key={unique_id}>
      <Chip label={value}/><div onClick={clickFunction} className="MuiChip-root close">&nbsp;</div>
    </div>
  }

  ToggleChip(label, fieldName){
    const uniqueIdPrefixed = 'extra_validators'
    const unique_id = uniqueIdPrefixed + '_'+fieldName

    let state = this.state[fieldName]==true

    let bound_values:any = {
      save_group:'extras',
      state,
      controller:this,
      unique_id: fieldName,
      onUpdate: this.syncFilters.bind(this)
    }


    let toggler:toggleCtrl = new toggleCtrl(bound_values)

    let clickFunction = toggler.toggleSelection.bind(toggler)

    const chainedCommands = {
      command1: () => {
        this.state.initSearch = false
      },
      command2: clickFunction,
      state: this.state
    }

    let commands:chainCommands = new chainCommands()
    clickFunction = commands.runCommands.bind(chainedCommands)

    return  <div key={unique_id}>
      <Chip label={label}/><div onClick={clickFunction} className="MuiChip-root close">&nbsp;</div>
    </div>
  }

  SelectionBoolean(value, unique_id, groupName,save_group='extras',onUpdate=null){
    const uniqueIdPrefixed = 'extra_' + unique_id
    let state = this.state[uniqueIdPrefixed]==true

    let bound_values:any = {
      state,
      controller:this,
      save_group,
      unique_id: uniqueIdPrefixed,
      onUpdate: onUpdate || this.syncFilters.bind(this)
    }
    
    let toggler:toggleCtrl = new toggleCtrl(bound_values)

    let clickFunction = toggler.toggleSelection.bind(toggler)

    const chainedCommands = {
      command1: () => {
        this.saveScrollPosition()
      },
      command2: clickFunction,
      state: this.state
    }

    let commands:chainCommands = new chainCommands()
    clickFunction = commands.runCommands.bind(chainedCommands)    

    return  <div className="indented"> <div key={uniqueIdPrefixed} className="selection">
    <FormControlLabel
      control={<Checkbox size="small" disableRipple={true} checked={state} onClick={clickFunction} color="primary" />}
      label={value}
      labelPlacement="end"
    />
    </div></div>
  }

  // for badges / validators
  SelectionNonMV(value, unique_id, groupName,save_group='extras',onUpdate=null,unique_postfix=''){
    const uniqueIdPrefixed = 'extra_' + unique_id

    let state = this.state[uniqueIdPrefixed] || []

    let found = false
    for (let val of state)
    {
      if (val==value) found = true;
    }

    let bound_values:any = {
      groupName,
      state,
      value,
      controller:this,
      save_group,
      unique_id: uniqueIdPrefixed,
      onUpdate: onUpdate || this.syncFilters.bind(this)
    }

        
    let toggler:toggleCtrl = new toggleCtrl(bound_values)

    let clickFunction = toggler.toggleValue.bind(toggler)
    const chainedCommands = {
      command1: () => {
        this.saveScrollPosition()
      },
      command2: clickFunction,
      state: this.state
    }

    let commands:chainCommands = new chainCommands()
    clickFunction = commands.runCommands.bind(chainedCommands)    

    return  <div key={uniqueIdPrefixed+unique_postfix} className="selection">
    <FormControlLabel
      control={<Checkbox size="small" disableRipple={true} checked={found} onClick={clickFunction} color="primary" />}
      label={value}
      labelPlacement="end"
    />
    </div>
  }

  saveScrollPosition = () => {
    if (this.contentRef.current) {
      localStorage.setItem('scrollPosition', this.contentRef.current.scrollTop);
    }
  };

  restoreScrollPosition = () => {
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (this.contentRef.current && savedScrollPosition) {
      this.contentRef.current.scrollTop = savedScrollPosition;
    }
  };


  private BadgesSideBarSection(icon=null, ref): React.ReactNode {
    // return <div class="heading">Badges</div>

    let updateResults = this.updateResults.bind(this)
    const heading="Validations"
    const secondColumnArea = 'Evidence-Based'
    let currentArea:any = null

    const columns:any = [[],[]]
    let colOn = 0

    for (let v_indx=0;v_indx< this.state.validator_names.length;v_indx++)
    {
      const validator = this.state.validator_names[v_indx]
      let area = validator.area
      if (area==secondColumnArea)
      {
          colOn = 1
      }
      if (area!=currentArea) {
        currentArea = area
        columns[colOn].push(<h4 key={`badge_area_${v_indx}`}>{area}</h4>)
      }
      columns[colOn].push( this.SelectionNonMV(validator.name, `validators`, "badges_heading","extras",updateResults,`_${colOn}_${v_indx}`) )
    }



    return <div className="indented" ref={ref}>
      {
        // icon
      }
      <div className="filterCol">
      {this.Collapsable("Validations", 'badges_heading', !!this.state.badges_heading)}
      </div>

      {
        columns.map( column => {return <div className="filterCol">{column}</div>})
      }

    </div>
  }

  private PrivacyAdditionalItems(): React.ReactNode {
    return this.SelectionBoolean("SDPC Agreement", "spdc_option", "group_spdcNationalDataPrivacyAgreementLabel_heading")
  }

  private getCompareList = () => {
    const ls_compare:any = localStorage.getItem('compare')
    return JSON.parse(ls_compare) || {};
  }

  private updateCheckboxStates = () => {
    const compareList = this.getCompareList();
    const {my_product_keys,my_product_lookup} = this.getFromLocalstorage('my_list')
    my_product_keys.forEach(product_id => {
        const product_key = `compare_${product_id}`;
        const checkbox:any = document.getElementById(product_key);
        if (checkbox) {
            checkbox.checked = !!compareList[product_key];
        }
    });
    const selectAllCheckbox:any = document.getElementById('select_all');
    if (selectAllCheckbox) {
      const newState = my_product_keys.length > 0 && my_product_keys.every(product_id => !!compareList[`compare_${product_id}`])
      selectAllCheckbox.checked = newState
      const selectAllLabel = document.querySelector('label[for="select_all"]');
      if (selectAllLabel) {
          selectAllLabel.textContent = newState ? 'Deselect All' : 'Select All';
      }
    }
  }

  private handleCheckboxChange = (product_key) => {
    const compareList = this.getCompareList();
    if (compareList[product_key]) {
        delete compareList[product_key];
    } else {
        compareList[product_key] = true;
    }
    localStorage.setItem('compare', JSON.stringify(compareList));
    this.updateCheckboxStates()
  }

  private handleSelectAllChange = (e) => {
    const allChecked = e.target.checked;
    const compareList = {};
    const {my_product_keys,my_product_lookup} = this.getFromLocalstorage('my_list')

    if (allChecked) {
        my_product_keys.forEach(product_id => {
            compareList[`compare_${product_id}`] = true;
        });
        localStorage.setItem('compare', JSON.stringify(compareList));
    } else {
        localStorage.removeItem('compare');
    }
    this.updateCheckboxStates()
  }

  BaseComponentRender(props)
  {
    let { menu, title, children, className,isCompare } = props
    let { order, filters, pageNum, page_info, exploreChoice, showLogin, userData, showExploration,dynamicPopup,extra_validators,extra_spdc_option } = this.state
    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                product_url
                login_url
                logout_url
                vendor_form_target
                user_detail_url
                save_user_detail_url
                cache_duration
                sentry_dsn
                enable_ga
            }
        }
    }
    `)

    const enable_ga =  data.site.siteMetadata.enable_ga || false
    this.login_url = data.site.siteMetadata.login_url || ''
    this.logout_url = data.site.siteMetadata.logout_url || ''
    this.vendor_form_target = data.site.siteMetadata.vendor_form_target || ''
    this.user_detail_url = data.site.siteMetadata.user_detail_url || ''
    this.save_user_detail_url = data.site.siteMetadata.save_user_detail_url || ''

    let headingFieldNamesLookup = {}

    if (this.firstRender)
    {
      useScript('/js/userbackV2.js')
      useScript('/js/fbPixel.js')

      const { application_api_key, application_id,indexName } = useSiteMetadata()
      aa('init', {
        appId: application_id,
        apiKey: application_api_key
      })
      this.state.indexName = indexName

  
      try {
        Sentry.init({
          dsn: data.site.siteMetadata.sentry_dsn,
        
          // This sets the sample rate to be 10%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: 0.1,
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 1.0,
        
          integrations: [new Sentry.Replay()],
        })
        } catch (e) {
      }
  
      amplitude.getInstance().init(AMP_API_KEY);

      if (enable_ga && this.isBrowser) {
        const win:any=window;
        win.dataLayer = win.dataLayer || [];
        gtag('js', new Date());
        gtag('config', 'G-QPL1CS9ZEQ');
      }
      this.firstRender = false
    }

    let callback = this.metaLoaded.bind(this)

    const {my_product_keys,my_product_lookup} = this.getFromLocalstorage('my_list')
    const compare_info = this.getFromLocalstorage('compare')

    this.state.my_product_keys = my_product_keys
    this.state.my_product_lookup = my_product_lookup

    let my_compare_lookup=compare_info.my_product_lookup
    let my_compare_keys=compare_info.my_product_keys
    this.state.compare_lookup = my_compare_lookup
    this.state.compare_keys = my_compare_keys
    this.state.compare_maxed = this.state.compare_keys?.length >= 10


    let menuLinks:any = {
        about: {link:"/about/",title:"About"},
        articles_page: {link:"/articles/",title:"Articles"},
        evaluations_page: {link:"/evaluations/",title:"Evaluation"},
        validations_page: {link:"/validations/",title:"Validations"},
    }

    let menuKeys = Object.keys(menuLinks)

    let myStorage:any = {}

    const isBrowser = typeof window !== "undefined"

    const currentYear = new Date().getUTCFullYear();
    
    if (isBrowser)
    {
        myStorage= window.localStorage
    }
    
    if (!!className)
    {
        className = className+" "+menu
    }
    else
    {
        className = menu
    }

    let cacheTimeOut = data.site.siteMetadata.cache_duration || CACHE_TIMEOUT_DEFUALT
    let lastLoaded = myStorage.metaDate
    let currentDate = new Date().getTime()
    let metaRetries = 0

    if (!lastLoaded || (currentDate-lastLoaded)>cacheTimeOut)
    {
      let metaHandler = (result) => {
        if (result.data.result=='ok')
        {
          // NOTE: new validation fields seen in result.data here
          //
          // NOTE: switching tab in "Validations" also come to this point

            myStorage.metaDate = new Date().getTime()
            myStorage.productFieldOrderMeta = JSON.stringify(result.data.productFieldOrderMeta)
            myStorage.mv_lookups = JSON.stringify(result.data.mv_lookups)
            myStorage.validator_names = JSON.stringify(result.data.validator_names)
        
            if (!!callback)
            {
                callback(result.data);
            }
        }
        else {
          metaRetries++
          if (metaRetries<10)
          {
            axios
            .get(data.site.siteMetadata.product_url+'metadata')
            .then(metaHandler)
          }
        }
    }
        axios
            .get(data.site.siteMetadata.product_url+'metadata')
            .then(metaHandler)
    }

    let selectAllToCompare = this.selectAllToCompare.bind(this)

    let allSelected = true
    let selectedCount = 0
    for (let product_id of this.state.my_product_keys)
    {
        let product_key = 'compare_'+product_id
        if (!this.state[product_key])
        {
          allSelected = false
        }
        else
        {
          selectedCount++
        }
    }

    if (allSelected)
    {
      selectAllToCompare = this.deselectAllToCompare.bind(this)
    }
    let windowWidth=(this.isBrowser)?window.innerWidth:350
    this.isTablet = false

    let nav_state = (!!this.state['nav_state'])
    let bound_values:any = {unique_id:'nav_state',state:nav_state,controller:this,save_group:'nav'}
    let toggler:toggleCtrl = new toggleCtrl(bound_values)
    let navClickFunction = toggler.toggleSelection.bind(toggler)

    if (this.isBrowser) {
      let userAgent = navigator.userAgent
      let result:any = deviceDetector.parse(userAgent)
      this.isTablet = result.device.type=='tablet' || result.device.type=='desktop'
    }

    if (this.isTablet)
    {
      this.isTablet = true
    }

    let controller = this
    let onCompareClick =  isCompare?
      () => {
        if (selectedCount>1)
          this.setState({initSearch:false,myListOpen:false})
      }
      :
      ()=>{if (selectedCount>1) navigate("/compare/")}

    if (!this.isBrowser)
    {
      return <></>
    }

    const filterCount = (extra_spdc_option?1:0)+(extra_validators? extra_validators.length:0)+filters.length

    let needOgTag:boolean= ((location.pathname=='/product/') && !!(this.state.products[0]))

    let canonicalLink:any = null
    if (!!(this.state?.ultid) && !!(this.state?.siteUrl)){
      canonicalLink = this.state?.siteUrl+'/product/ultid/'+this.state?.ultid  // not for link with port
    }

    let productRenders = my_product_keys.map((product_id,indx)=>{
        let name = my_product_lookup['product_'+product_id]
        let product_key = 'compare_'+product_id
        let my_list_state = !!this.state[product_key]
        let updateCompareList = this.updateCompareList.bind(this)
        let bound_values:any = {unique_id:product_key,state:my_list_state,controller,save_group:'compare',onUpdate:()=>{updateCompareList(!my_list_state)}}
        let toggler:toggleCtrl = new toggleCtrl(bound_values)
        let clickFunction = toggler.toggleSelection.bind(toggler)

        return <div key={product_key}>
            <FormControlLabel
            control={<Checkbox size="small" disableRipple={true} disabled={this.state.compare_maxed && !my_list_state} checked={my_list_state} onClick={clickFunction} color="primary" />}
            label={name}
            labelPlacement="end"
            />
        </div>
    })

    let searchProducts = this.search.bind(this)
    let setLoggedIn = this.setLoggedIn.bind(this)
    const setProdObjMapping = this.setProdObjMapping.bind(this)
    let highlightStrMapCallback = this.setHighlightStrMap.bind(this)
    let loadLock = this.loadLock
    this.loadLock = true
    const logged_in = (userData && userData.login)?true:false
    const isAllSelected = my_product_keys.length > 0 && my_product_keys.every(product_id => this.getCompareList()[`compare_${product_id}`]);
    const handleSelectAllChange = this.handleSelectAllChange.bind(this)

    return (
        <ThemeProvider theme={theme}>
        <Helmet defer={false}>
            <meta charSet="utf-8" />
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta name="google-site-verification" content="0B8lszGFnO7M-gtVtPlLIVS-3RS3wmrNNl_WbDteMPQ" />
            {(!!canonicalLink) && <link rel="canonical" href={canonicalLink}></link>}
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap" rel="stylesheet"/>
            <script src="https://kit.fontawesome.com/db8123fd0b.js" crossOrigin="anonymous"></script>
            {
              enable_ga && <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-PFD6BJB');`}</script>
            }
            {
              enable_ga && <script async src="https://www.googletagmanager.com/gtag/js?id=G-QPL1CS9ZEQ"></script>
            }
            {
              enable_ga && <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments)}
                gtag('js', new Date());

                gtag('config', 'G-QPL1CS9ZEQ');
              `}</script>
            }

            <script type="text/javascript">{`
             if ( (document.domain == "index-dev.edsurge.com") || (document.domain == "index-tst.edsurge.com")  || (document.domain == "index.edsurge.com")) {
              var mouseflowPath = window.location.host + window.location.pathname;
             };
              window._mfq = window._mfq || [];
              (function() {
                var mf = document.createElement("script");
                mf.type = "text/javascript"; mf.defer = true;
                mf.src = "//cdn.mouseflow.com/projects/7910ceed-da9a-46de-bf48-823b7404c2cd.js";
                document.getElementsByTagName("head")[0].appendChild(mf);
              })();
            `}</script>
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
                integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
                crossOrigin="anonymous"
                />
            {!!title &&  <title>{title}</title>}
            <html className={className+'_root'} lang='en'></html>

            { needOgTag &&  <meta property="og:title" content={this.state.products[0].name} /> }
            { needOgTag &&  <meta property="og:type" content='product' /> }
            { needOgTag &&  <meta property="og:url" content={window.location.href} /> }            
            { needOgTag &&  <meta property="og:image" content={this.state.products[0].image_url} /> }
            { needOgTag &&  <meta property="og:description" content={this.state.products[0].publicDescription} /> }


        </Helmet>
        {
          showLogin && <LoginRequiredPopup 
            loginUrl={this.login_url}
            closeFn={()=>{this.setState({showLogin: false})}}
          />
        }
        <div id="body_wrapper" className={(logged_in?'logged_in':'logged_out')}>
            <div id="header_bg"/>
            <div id="header" className={className}>
              <div id="white_bg"/>
              <a id="edsurge_home" href="/">Product Index</a>
              <a id="iste_home" href="https://www.iste.org/" target="_blank">ISTE</a>
              <a id="ascd_home" href="https://www.ascd.org/" target="_blank">ASCD</a>

              <UserLogin
                loginUrl={this.login_url}
                logoutUrl={this.logout_url}
                userDetailUrl={this.user_detail_url}
                setLoggedIn={ setLoggedIn }
                userData={userData}
                loadLock={loadLock}
              />
              <div id="selection">
                  {
                      (my_product_keys.length>0) &&
                      <div className="menu"  key="mylist">
                        <span onClick={(state)=>{navigate("/my_list/")}}>My List  <div className="circle">{my_product_keys.length}</div></span>

                        <div className="widget">
                          <a href="/my_list/">View List</a>
                          <div className="sep"></div>
                          <button type="button" className="compare btn btn-primary" onClick={() => navigate("/compare/")}>Compare Selected</button>

                          <div className="comp">
                              <input
                                  type="checkbox"
                                  id="select_all"
                                  onChange={(e) => {handleSelectAllChange(e);return false}}
                              />
                              <label htmlFor="select_all">Select All</label>
                          </div>

                          {my_product_keys.map((product_id) => {
                              const product_key = `compare_${product_id}`;
                              const name = my_product_lookup[`product_${product_id}`];
                              const compareList = this.getCompareList();

                              return (
                                  <div className="comp" key={product_key}>
                                      <input
                                          type="checkbox"
                                          id={product_key}
                                          onChange={() => this.handleCheckboxChange(product_key)}
                                      />
                                      <label htmlFor={product_key}>{name}</label>
                                  </div>
                              );
                          })}
                      </div>

                      </div>
                  }
                  {
                      (my_product_keys.length==0) &&
                      <div className={`menu is_${this.state.myListOpen?'open':'closed'}`}>
                        <Link to="/my_list/" className="single"><span onClick={(state)=>{controller.setState({navOpen:false,myListOpen:!this.state.myListOpen})}}>My List  <div className="circle">{my_product_keys.length}</div></span></Link>
                      </div>

                  }
              </div>
              <div id="learn">
                  <div className="menu">
                    <span>Learn <div className="circle" /></span>
                      <div id="nav">
                        <ul>
                            {
                                menuKeys.map((menuName,index) => {
                                    let listItem = menuLinks[menuName];
                                    return <li key={menuName} className={ (menu==menuName || menu==listItem.alt)?"selected":""}><a href={listItem.link}>{listItem.title}</a></li>
                                })
                            }
                        </ul>
                      </div>
                  </div>
              </div>
              <div id="mobile_menu">
              <span><div className="circle"></div></span>
              <div className="nav">
                <ul>
                {
                    menuKeys.map((menuName,index) => {
                        let listItem = menuLinks[menuName];
                        return <li key={menuName} className={ (menu==menuName || menu==listItem.alt)?"selected":""}><a href={listItem.link}>{listItem.title}</a></li>
                    })
                }
                  <li><a href="/my_list/">My List</a></li>
                  {
                    logged_in ?
                    <li>
                      <div>My Account
                        <div><a href="/logout">Logout</a></div>
                      </div>
                    </li>
                    :
                    <li>
                      <a href="/sso/login/request">
                      Login</a>
                    </li>
                  }
                </ul>
              </div>

              </div>
            </div>
            <div id="searchbar" key="searchbar">
              <div className="centre">
                <Searcher 
                    id="search" placeholder={"Discovery Starts Here"} variant="outlined"
                    onChange={searchProducts}
                    setProdObjMapping={setProdObjMapping}
                    state={this.state}
                    key="searcher"
                    order={order}
                    filters={filters}
                    pageNum={pageNum}
                    filterGroupings={headingFieldNamesLookup}
                    setHighlightStrMap={ highlightStrMapCallback }
                    legacy={false}
                    homepage={this.homepage}
                    ></Searcher>

                {
                  filterInfo.map( (info, index ) => {
                    return <div key={'ql'+index} onClick={()=>{this.setState({showExploration:true, exploreChoice:info.choice})}} className={`quicklink ${info.iconClass}`}><span>{info.label}</span>
                    {
                      (filterCount>0 && index==(filterInfo.length-1)) && <div className='circle'>{filterCount}</div>
                    }</div>
                  })
                }
              </div>
            </div>
            {
              dynamicPopup
            }
            {
              showExploration && <ExplorePopup 
                parent={this}
                contentRef={this.contentRef}
                
                showingCount={page_info?.nbHits}
                searchProducts={searchProducts}
                setProdObjMapping={setProdObjMapping}
                order={order}
                pageNum={pageNum}
                headingFieldNamesLookup={headingFieldNamesLookup}
                highlightStrMapCallback={highlightStrMapCallback}
                exploreChoice={exploreChoice}
                homepage={this.homepage}
              />
            }
            <div id="container" className={className}>
            <div id="copy">
                {children}

              <div id="footer" className={this.state.openDrawer?"open":""}>
              <div id="footer_nav">
                <ul className="primary">
                  <li><a href="http://edsurge.com">EdSurge</a></li>
                    {
                        menuKeys.map((menuName,index) => {
                            let listItem = menuLinks[menuName];
                            return <li key={"footer_"+menuName}><a href={listItem.link}>{listItem.title}</a></li>
                        })
                    }
                </ul>
                <ul className="secondary">
                    <li className="clear"><a href="https://www.edsurge.com/ethics-and-policies">Ethics Statement</a></li>
                    <li><a target="_blank" href="https://iste-policies.s3.amazonaws.com/legal+documents/Terms+of+Use.pdf">Terms of Use</a></li>
                    <li><a target="_blank" href="https://iste-policies.s3.amazonaws.com/legal+documents/Privacy+Policy.pdf">Privacy Policy</a></li>
                    <li><a target="_blank" href="https://iste-policies.s3.amazonaws.com/legal+documents/api_and_data_license_agreement.pdf">API &amp; Data License Agreement</a></li>
                    <li><a target="_blank" href="https://iste-policies.s3.amazonaws.com/legal+documents/Cookie+Policy.pdf">Cookie Policy</a></li>
                    <li><a href="https://helpdesk.edsurge.com/">Help</a></li>
                </ul>
              </div>
              <div className="clear"/>
              <div id="isteInfo">
                <span>&copy; {`${currentYear}`} International Society for Technology in Education (ISTE), All Rights Reserved</span>
              </div>
              <div className="clear"/>
            </div>

            </div>
            <div className="clear"/>
          </div>

        <div className="clear"/>
      </div>
      </ThemeProvider>
    )
  }
}


export default CommonProduct
